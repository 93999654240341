export const Permission = (data, id, type) => {
  const returnPermission = data
    ?.map((item) => {
      return item.resource_id == id &&
        (type === 1
          ? item.can_read
          : type === 2
          ? item.can_insert
          : type === 3
          ? item.can_update
          : item.can_delete)
        ? true
        : false;
    })
    .filter((item) => item === true);
  return returnPermission[0];
};

export const fetchPermissionId = (data, type) => {
  return data
    ?.filter((x) => x.resource.name == type)
    .map((item) => item.resource.id);
};
