export const ActionTypes = {
  //Auth
  AuthUser: "AuthUser",
  GetSetLogOut: "GetSetLogOut",

  //Language
  SetLanguage: "SetLanguage",
  SetLanguageData: "SetLanguageData",

  //Listing
  GetAllCountry: "GetAllCountry",
  GetAllProvince: "GetAllProvince",
  GetRoleForUser: "GetRoleForUser",
  GetHotelChainUsers: "GetHotelChainUsers",
  GetHotelUsers: "GetHotelUsers",
  GetHotelChainsForHotel: "GetHotelChainsForHotel",
  GetHotelForHotelStaff: "GetHotelForHotelStaff",
  GetCustomerForOrder: "GetCustomerForOrder",
  GetResellerForKiosks: "GetResellerForKiosks",
  GetHotelForKiosks: "GetHotelForKiosks",
  GetRMAOpenBy: "GetRMAOpenBy",
  GetRMAClient: "GetRMAClient",
  GetRMAHotels: "GetRMAHotels",
  GetRMAKiosks: "GetRMAKiosks",
  GetKioskUsers: "GetKioskUsers",
  GetKioskType: "GetKioskType",
  GetKioskModel: "GetKioskModel",
  GetRoomLocksForHotel: "GetRoomLocksForHotel",
  GetRoomLocksForKiosk: "GetRoomLocksForKiosk",
  //Loader
  SetLoadder: "SetLoadder",

  //Role
  SetRole: "SetRole",
  SetRoleType: "SetRoleType",
  SetRoleUrl: "SetRoleUrl",
  SetSelectedRole: "SetSelectedRole",
  SetSelectAllRole: "SetSelectAllRole",

  //Role User
  SetRoleUsers: "SetRoleUsers",
  SetRoleUserUrl: "SetRoleUserUrl",
  SetSelectedRoleUser: "SetSelectedRoleUser",
  SetSelectAllRoleUser: "SetSelectAllRoleUser",

  //User
  SetUsers: "SetUsers",
  SetUserUrl: "SetUserUrl",
  SetSelectedUser: "SetSelectedUser",
  SetSelectAllUser: "SetSelectAllUser",
  // GetRoleForUser: "GetRoleForUser",
  SetSingleUser: "SetSingleUser",
  // GetAllCountry: "GetAllCountry",
  // GetAllProvince: "GetAllProvince",
  SetUserFilter: "SetUserFilter",
  GetHotelForUserStaff: "GetHotelForUserStaff",

  //Modules
  SetModules: "SetModules",

  //hotel Chain
  SetHotelChain: "SetHotelChain",
  SetHotelChainUrl: "SetHotelChainUrl",
  SetSelectedHotelChain: "SetSelectedHotelChain",
  SetSelectAllHotelChain: "SetSelectAllHotelChain",

  //hotel
  SetHotels: "SetHotels",
  SetHotelsUrl: "SetHotelsUrl",
  SetSelectAllHotel: "SetSelectAllHotel",
  SetSelectedHotel: "SetSelectedHotel",
  SetHotelUserForHotel: "SetHotelUserForHotel",
  SetHotelChainForHotel: "SetHotelChainForHotel",
  SetResellerForHotel: "SetResellerForHotel",
  SetSingleHotel: "SetSingleHotel",
  SetPropertyTypeForHotel: "SetPropertyTypeForHotel",
  SetCategoryForHotel: "SetCategoryForHotel",
  SetSegmentForHotel: "SetSegmentForHotel",
  SetHotelFilter: "SetHotelFilter",

  //orders
  SetOrders: "SetOrders",
  SetOrdersUrl: "SetOrdersUrl",
  SetSelectedOrder: "SetSelectedOrder",
  SetSelectAllOrders: "SetSelectAllOrders",
  SetKioskForOrder: "SetKioskForOrder",

  //kiosk
  SetKiosk: "SetKiosk",
  SetKioskUrl: "SetKioskUrl",
  SetSelectedKiosk: "SetSelectedKiosk",
  SetSelectAllKiosk: "SetSelectAllKiosk",
  SetKioskStages: "SetKioskStages",
  SetKioskFilter: "SetKioskFilter",

  //kiosk breakdown
  SetKioskBreakDown: "SetKioskBreakDown",
  SetKioskBreakDownUrl: "SetKioskBreakDownUrl",
  SetSelectedKioskBreakDown: "SetSelectedKioskBreakDown",
  SetSelectAllKioskBreakDown: "SetSelectAllKioskBreakDown",
  SetKioskForKioskBreakDown: "SetKioskForKioskBreakDown",

  //RMA
  SetRMA: "SetRMA",
  SetRMAUrl: "SetRMAUrl",
  SetSelectedRMA: "SetSelectedRMA",
  SetSelectAllRMA: "SetSelectAllRMA",
  SetRMAStages: "SetRMAStages",
  SetRMALocation: "SetRMALocation",
  SetDeviceForRMA: "SetDeviceForRMA",
  SetRMAFilter: "SetRMAFilter",

  //reseller
  SetReseller: "SetReseller",
  SetResellerUrl: "SetResellerUrl",
  SetSelectedReseller: "SetSelectedReseller",
  SetSelectAllReseller: "SetSelectAllReseller",
  GetResellerCategory: "GetResellerCategory",
  SetResellerFilter: "SetResellerFilter",

  //Service Plan
  SetServicePlan: "SetServicePlan",
  SetServicePlanUrl: "SetServicePlanUrl",
  SetSelectedServicePlan: "SetSelectedServicePlan",
  SetSelectAllServicePlan: "SetSelectAllServicePlan",

  //Kiosk Subscription
  SetKioskSubscription: "SetKioskSubscription",
  SetKioskSubsciptionUrl: "SetKioskSubsciptionUrl",
  SetSelectedKioskSubscription: "SetSelectedKioskSubscription",
  SetSelectAllKioskSubscription: "SetSelectAllKioskSubscription",
  SetKioskForKioskSubcription: "SetKioskForKioskSubcription",
  SetPlanForKiosk: "SetPlanForKiosk",

  //Kiosk COnfiguration
  SetKioskConfiguration: "SetKioskConfiguration",
  SetKioskConfigurationUrl: "SetKioskConfigurationUrl",
  SetSelectedKioskConfiguration: "SetSelectedKioskConfiguration",
  SetSelectAllKioskConfiguration: "SetSelectAllKioskConfiguration"
};
