import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "../../../Shared_Components/Input/Input";
import API from "../../../Api/Api";
import toast from "react-hot-toast";
import PasswordMatch from "../../../Helper/PasswordMatch";
import PasswordEfficiency from "../../../Helper/PasswordEfficiency";
import { ExportData } from "../../../config/language";
import { backEndMessages } from "../../../Helper/common";
const ResetPassword = () => {
  //state
  const [eye, seteye] = useState(false);
  const [confirmEye, setconfirmEye] = useState(false);
  const [passwordEfficiency, setpasswordEfficiency] = useState(0);
  //other
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.search.slice(7);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  //handle reset password
  const handleResetPassword = async (data) => {
    if (data.password == data.confirmpassword) {
      const sendData = {
        password: data.confirmpassword,
        token,
        project: "KIOSK_MANUFACTURER",
      };
      const response = await API("POST", "users/reset-password", "", sendData);
      if (response.data.status) {
        toast.success(backEndMessages(response.data.message));
        navigate("/");
      } else {
        toast.error(backEndMessages(response.data.message));
      }
    }
  };

  // Checking Password Strength
  const checkPasswordStrength = (password) => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    const mediumRegex = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[0-9]))|((?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9])))(?=.{7,})"
    );
    const SosoRegex = new RegExp(
      "^(((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{5,})"
    );
    const weekRegex = new RegExp("^(((?=.*[a-z]))|((?=.*[A-Z])))(?=.{3,})");
    //Check Password strength
    if (strongRegex.test(password)) {
      setpasswordEfficiency(100);
    } else if (mediumRegex.test(password)) {
      setpasswordEfficiency(80);
    } else if (SosoRegex.test(password)) {
      setpasswordEfficiency(60);
    } else if (weekRegex.test(password)) {
      setpasswordEfficiency(40);
    } else if (password === "") {
      setpasswordEfficiency(0);
    } else {
      setpasswordEfficiency(20);
    }
  };

  // Password Check
  const passwordHandle = () => {
    //get password
    const getpassword = watch("password");
    //check strength
    checkPasswordStrength(getpassword);
  };

  // Use Effect
  useEffect(() => {
    passwordHandle();
  });

  return (
    <form
      className="form w-100"
      id="kt_new_password_form"
      onSubmit={handleSubmit(handleResetPassword)}
    >
      {/*begin::Heading*/}
      <div className="text-center mb-10">
        {/*begin::Title*/}
        <h1 className="text-dark mb-3">{ExportData("RESET_HEADER_TXT")}</h1>
        {/*end::Title*/}
        {/*begin::Link*/}
        <div className="text-gray-400 fw-bold fs-4">
          {ExportData("RESET_SUBLINE_TXT")} ?{" "}
          <Link to="/" className="link-primary fw-bolder">
            {ExportData("SIGN_IN_HERE")}
          </Link>
        </div>
        {/*end::Link*/}
      </div>
      {/*begin::Heading*/}
      {/*begin::Input group*/}
      <div className="mb-10 fv-row" data-kt-password-meter="true">
        {/*begin::Wrapper*/}
        <div className="mb-1">
          {/*begin::Label*/}
          <label className="form-label fw-bolder text-dark fs-6">
            <span className="required">{ExportData("PASSWORD")}</span>
          </label>
          {/*end::Label*/}
          {/*begin::Input wrapper*/}
          <div className="position-relative mb-3">
            <Input
              name="password"
              type={eye ? "text" : "password"}
              pattern="password"
              className="form-control form-control-lg form-control-solid remove_invalid"
              register={register}
              required={true}
              minLength="8"
              maxLength={30}
              errortype={errors.password?.type}
              keyName="PASSWORD"
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-25"
              data-kt-password-meter-control="visibility"
              onClick={() => seteye(!eye)}
            >
              {eye ? (
                <i className="bi bi-eye fs-2"></i>
              ) : (
                <i className="bi bi-eye-slash fs-2"></i>
              )}
            </span>
          </div>
          <PasswordEfficiency passwordEfficiency={passwordEfficiency} />
        </div>
        <div className="text-muted">
          {ExportData("RESET_PASSWORD_BOTTOM_TXT")}.
        </div>
      </div>
      {/*begin::Input group=*/}
      <div className="fv-row mb-10">
        <div className="mb-1">
          {/*begin::Label*/}
          <label className="form-label fw-bolder text-dark fs-6">
            <span className="required">{ExportData("CONFIRM_PASSWORD")}</span>
          </label>
          <div className="position-relative mb-3">
            <Input
              name="confirmpassword"
              type={confirmEye ? "text" : "password"}
              className="form-control form-control-lg form-control-solid remove_invalid"
              register={register}
              maxLength={30}
              keyName="CONFIRM_PASSWORD"
            />
            <PasswordMatch
              getpassword={watch("password")}
              confirmPassword={watch("confirmpassword")}
              errortype={errors.password?.type}
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-25"
              data-kt-password-meter-control="visibility"
              onClick={() => setconfirmEye(!confirmEye)}
            >
              {confirmEye ? (
                <i className="bi bi-eye fs-2"></i>
              ) : (
                <i className="bi bi-eye-slash fs-2"></i>
              )}
            </span>
          </div>
        </div>
      </div>
      {/*end::Input group=*/}
      {/*begin::Action*/}
      <div className="text-center">
        <button
          type="submit"
          id="kt_new_password_submit"
          className="btn btn-lg btn-primary fw-bolder"
        >
          <span className="indicator-label">{ExportData("SUBMIT")}</span>
        </button>
      </div>
      {/*end::Action*/}
    </form>
  );
};

export default ResetPassword;
