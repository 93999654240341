import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "../../../Shared_Components/Input/Input";
import API from "../../../Api/Api";
import toast from "react-hot-toast";
import { ExportData } from "../../../config/language";
import { backEndMessages } from "../../../Helper/common";
const ForgotPassword = () => {
  //other
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  //handle forgot password
  const handleForgotPassword = async (data) => {
    data.project = "KIOSK_MANUFACTURER";
    const response = await API("POST", "users/forgot-password", "", data);
    if (response.data.status) {
      reset();
      toast.success(backEndMessages(response.data.message));
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      toast.error(backEndMessages(response.data.message));
    }
  };

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        id="kt_login_password_reset_form"
        onSubmit={handleSubmit(handleForgotPassword)}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">{ExportData("FORGOT_PASSWORD")} ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            {ExportData("FORGOT_SUBLINE_TXT")}.
          </div>
          {/* end::Link */}
        </div>
        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <Input
            register={register}
            name="email"
            required={true}
            errortype={errors.email?.type}
            className="form-control form-control-lg form-control-solid"
            pattern="email"
            labelname={ExportData("EMAIL")}
            labelclassName="form-label fs-6 fw-bolder text-dark"
            autoComplete="off"
            keyName="EMAIL"
          />
        </div>
        {/* end::Form group */}
        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
          >
            <span className="indicator-label">{ExportData("SUBMIT")}</span>
          </button>
          <Link to="/">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
            >
              {ExportData("CANCEL")}
            </button>
          </Link>{" "}
        </div>
      </form>
    </>
  );
};
export default ForgotPassword;
