import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    Orders: {},
    OrdersUrl: {
        id: null,
        page: "",
        per_page: "",
        sortBy: null,
        sort: null,
        keyword: "",
        customer_id: null
    },
    SelectedOrder: [],
    SelectAllOrders: false,
    KioskForOrder: []
};
const OrderReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetOrders:
            return {
                ...state,
                Orders: payload,
            };
        case ActionTypes.SetOrdersUrl:
            return {
                ...state,
                OrdersUrl: payload,
            };
        case ActionTypes.SetSelectedOrder:
            return {
                ...state,
                SelectedOrder: payload,
            };
        case ActionTypes.SetSelectAllOrders:
            return {
                ...state,
                SelectAllOrders: payload,
            };
        case ActionTypes.SetKioskForOrder:
            return {
                ...state,
                KioskForOrder: payload,
            };
        default:
            return state;
    }
};
export default OrderReducer;
