import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    RMA: {},
    RMAUrl: {
        id: null,
        page: "",
        per_page: "",
        sortBy: null,
        sort: null,
        keyword: "",
        customer_id: null
    },
    SelectedRMA: [],
    SelectAllRMA: false,
    RMAStages: [],
    RMALocation: [],
    RMADevices: [],
    RMAFilter: {}
};
const RMAReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetRMA:
            return {
                ...state,
                RMA: payload,
            };
        case ActionTypes.SetRMAUrl:
            return {
                ...state,
                RMAUrl: payload,
            };
        case ActionTypes.SetSelectedRMA:
            return {
                ...state,
                SelectedRMA: payload,
            };
        case ActionTypes.SetSelectAllRMA:
            return {
                ...state,
                SelectAllRMA: payload,
            };
        case ActionTypes.SetRMAStages:
            return {
                ...state,
                RMAStages: payload,
            };
        case ActionTypes.SetRMALocation:
            return {
                ...state,
                RMALocation: payload,
            };
        case ActionTypes.SetDeviceForRMA:
            return {
                ...state,
                RMADevices: payload,
            };
        case ActionTypes.SetRMAFilter:
            return {
                ...state,
                RMAFilter: payload,
            };
        default:
            return state;
    }
};
export default RMAReducer;
