import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    HotelChain: {},
    HotelChainUrl: {
        page: 1,
        per_page: 10,
        sortBy: null,
        sort: null,
        keyword: "",
        status: null,
        country: null
    },
    SelectedHotelChain: [],
    SelectAllHotelChain: false,
};
const HotelChainReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetHotelChain:
            return {
                ...state,
                HotelChain: payload,
            };
        case ActionTypes.SetHotelChainUrl:
            return {
                ...state,
                HotelChainUrl: payload,
            };
        case ActionTypes.SetSelectedHotelChain:
            return {
                ...state,
                SelectedHotelChain: payload,
            };
        case ActionTypes.SetSelectAllHotelChain:
            return {
                ...state,
                SelectAllHotelChain: payload,
            };
        default:
            return state;
    }
};
export default HotelChainReducer;
