import React from "react";
import { ExportData } from "../../config/language";

const PasswordMatch = ({ getpassword, confirmPassword, errortype }) => {
  const getConfirmPasswordError = () => {
    if (getpassword?.length == 0 && errortype == undefined) {
      return null;
    } else {
      if (confirmPassword?.length == 0) {
        return `${ExportData("CONFIRM_PASSWORD")} ${ExportData("IS_REQUIRED")}`;
      } else {
        if (confirmPassword !== getpassword) {
          return ExportData("PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_SAME");
        } else {
          return null;
        }
      }
    }
  };
  return (
    <div className={`fv-plugins-message-container invalid-feedback d-block`}>
      <span role="alert">{getConfirmPasswordError()}</span>
    </div>
  );
};

export default PasswordMatch;
