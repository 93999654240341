import { ActionTypes } from "../../Constant/ActionType";
const initState = {
  Loader: false,
};
const LoaderReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SetLoadder:
      return {
        ...state,
        Loader: payload,
      };
    default:
      return state;
  }
};
export default LoaderReducer;
