import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    Reseller: {},
    ResellerUrl: {
        id: null,
        page: 1,
        per_page: 10,
        sortBy: null,
        sort: null,
        keyword: "",
    },
    SelectedReseller: [],
    SelectAllReseller: false,
    ResellerCategory: [],
    ResellerFilter: {},
};
const ResellerReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetReseller:
            return {
                ...state,
                Reseller: payload,
            };
        case ActionTypes.SetResellerUrl:
            return {
                ...state,
                ResellerUrl: payload,
            };
        case ActionTypes.SetSelectedReseller:
            return {
                ...state,
                SelectedReseller: payload,
            };
        case ActionTypes.SetSelectAllReseller:
            return {
                ...state,
                SelectAllReseller: payload,
            };
        case ActionTypes.GetResellerCategory:
            return {
                ...state,
                ResellerCategory: payload,
            };
        case ActionTypes.SetResellerFilter:
            return {
                ...state,
                ResellerFilter: payload,
            };
        default:
            return state;
    }
};
export default ResellerReducer;