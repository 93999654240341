import clsx from "clsx";
import React from "react";

const PasswordEfficiency = ({ passwordEfficiency }) => {
  return (
    <div
      className="d-flex align-items-center mb-3"
      data-kt-password-meter-control="highlight"
    >
      <div
        className={clsx(
          "flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2",
          passwordEfficiency > 20 ? "active" : ""
        )}
      ></div>
      <div
        className={clsx(
          "flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2",
          passwordEfficiency >= 60 ? "active" : ""
        )}
      ></div>
      <div
        className={clsx(
          "flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2",
          passwordEfficiency >= 80 ? "active" : ""
        )}
      ></div>
      <div
        className={clsx(
          "flex-grow-1 bg-secondary bg-active-success rounded h-5px",
          passwordEfficiency == 100 ? "active" : ""
        )}
      ></div>
    </div>
  );
};

export default PasswordEfficiency;
