import { ActionTypes } from "../../Constant/ActionType";

export const AuthUser = (payload) => {
  return {
    type: ActionTypes.AuthUser,
    payload: payload,
  };
};

export const SetLanguage = (payload) => {
  return {
    type: ActionTypes.SetLanguage,
    payload: payload,
  };
};
export const SetLanguageData = (payload) => {
  return {
    type: ActionTypes.SetLanguageData,
    payload: payload,
  };
};
export const GetSetLogOut = (payload) => {
  return {
    type: ActionTypes.GetSetLogOut,
    payload: payload,
  };
};
