import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import API from "../../../Api/Api";
import { ExportData } from "../../../config/language";
import { SetLanguageData } from "../../../Redux/Action/AuthAction/AuthAction";
const VerifyEmail = () => {
  //state
  const [message, setmessage] = useState("");
  //other
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = location.search.slice(7);

  //call language API
  useEffect(() => {
    if (token) SetDefaultLanguage(token);
  }, []);

  //get language API
  const SetDefaultLanguage = async (token) => {
    const response = await API(
      "GET",
      `master/get/translated-words?column=english`
    );
    if (response.data.status) {
      dispatch(SetLanguageData(response.data.data));
      SendToken(token, response.data.data);
    } else {
      console.log("error", response);
    }
  };

  const SendToken = async (token, ln) => {
    const response = await API("POST", "users/verify-user", "", { token });
    if (response.data.status) {
      setmessage(ln[response.data.message] || response.data.message);
    } else {
      setmessage(ln[response.data.message] || response.data.message);
    }
  };
  return (
    <div>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">{ExportData(message)}</h1>
      </div>
      <form>
        <div className="text-center">
          <button
            className="btn btn-lg btn-primary w-100 mb-5"
            onClick={() => navigate("/")}
          >
            <span className="indicator-label">{ExportData("LOGIN")}</span>
          </button>
        </div>
      </form>
    </div>
  );
};
export default VerifyEmail;
