import React from "react";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import {
  DashboardIcon,
  sidebar_icon,
  UsersIcon,
  Role,
} from "../../../../Assets";
import { useSelector } from "react-redux";
import { Permission, fetchPermissionId } from "../../../../Helper/Permission";
import { config } from "../../../../config/config";
import { ExportData } from "../../../../config/language";

export function AsideMenuMain() {
  //state
  const data = useSelector((state) => state.AuthReducer.Auth.permissions);
  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon={DashboardIcon}
        title={ExportData("DASHBOARD")}
        fontIcon="bi-app-indicator"
      />
      {(Permission(data, fetchPermissionId(data, config.access.ROLES), 1) ||
        Permission(data, fetchPermissionId(data, config.access.USERS), 1)) && (
        <AsideMenuItemWithSub
          to="/user-management"
          icon={UsersIcon}
          title={ExportData("USER_MANAGEMENT")}
          fontIcon="bi-app-indicator"
        >
          {Permission(
            data,
            fetchPermissionId(data, config.access.ROLES),
            1
          ) && (
            <AsideMenuItem
              to="/roles"
              icon={sidebar_icon}
              title={ExportData("ROLES")}
              fontIcon="bi-app-indicator"
            />
          )}
          {Permission(
            data,
            fetchPermissionId(data, config.access.USERS),
            1
          ) && (
            <AsideMenuItem
              to="/users"
              icon={sidebar_icon}
              title={ExportData("USERS")}
              fontIcon="bi-app-indicator"
            />
          )}
        </AsideMenuItemWithSub>
      )}
      {(Permission(
        data,
        fetchPermissionId(data, config.access.HOTELCHAIN),
        1
      ) ||
        Permission(data, fetchPermissionId(data, config.access.HOTEL), 1) ||
        Permission(
          data,
          fetchPermissionId(data, config.access.HOTELSTAFF),
          1
        )) && (
        <AsideMenuItemWithSub
          to="/hotel-management"
          icon={UsersIcon}
          title={ExportData("HOTEL_MANAGEMENT")}
          fontIcon="bi-app-indicator"
        >
          {Permission(
            data,
            fetchPermissionId(data, config.access.HOTELCHAIN),
            1
          ) && (
            <AsideMenuItem
              to="/chains"
              icon={sidebar_icon}
              title={ExportData("HOTEL_CHAIN")}
              fontIcon="bi-app-indicator"
            />
          )}
          {Permission(
            data,
            fetchPermissionId(data, config.access.HOTEL),
            1
          ) && (
            <AsideMenuItem
              to="/hotels"
              icon={sidebar_icon}
              title={ExportData("HOTELS")}
              fontIcon="bi-app-indicator"
            />
          )}
          {Permission(
            data,
            fetchPermissionId(data, config.access.HOTELSTAFF),
            1
          ) && (
            <AsideMenuItem
              to="/staff"
              icon={sidebar_icon}
              title={ExportData("HOTEL_STAFF")}
              fontIcon="bi-app-indicator"
            />
          )}
        </AsideMenuItemWithSub>
      )}
      {(Permission(data, fetchPermissionId(data, config.access.Orders), 1) ||
        Permission(data, fetchPermissionId(data, config.access.RMA), 1)) && (
        <AsideMenuItemWithSub
          to="/orders-management"
          icon={UsersIcon}
          title={ExportData("ORDERS_MANAGEMENT")}
          fontIcon="bi-app-indicator"
        >
          {Permission(
            data,
            fetchPermissionId(data, config.access.Orders),
            1
          ) && (
            <AsideMenuItem
              to="/orders"
              icon={sidebar_icon}
              title={ExportData("ORDERS")}
              fontIcon="bi-app-indicator"
            />
          )}

          {Permission(data, fetchPermissionId(data, config.access.RMA), 1) && (
            <AsideMenuItem
              to="/rma"
              icon={sidebar_icon}
              title={ExportData("RMA")}
              fontIcon="bi-app-indicator"
            />
          )}
        </AsideMenuItemWithSub>
      )}
      {(Permission(data, fetchPermissionId(data, config.access.KIOSK), 1) ||
        Permission(
          data,
          fetchPermissionId(data, config.access.KIOSKBREAKDOWN),
          1
        )) && (
        <AsideMenuItemWithSub
          to="/kiosk-management"
          icon={UsersIcon}
          title={ExportData("KIOSK_MANAGEMENT")}
          fontIcon="bi-app-indicator"
        >
          {Permission(
            data,
            fetchPermissionId(data, config.access.KIOSK),
            1
          ) && (
            <AsideMenuItem
              to="/kiosks"
              icon={sidebar_icon}
              title={ExportData("KIOSK")}
              fontIcon="bi-app-indicator"
            />
          )}
          {Permission(
            data,
            fetchPermissionId(data, config.access.KIOSKBREAKDOWN),
            1
          ) && (
            <AsideMenuItem
              to="/kioskbreakdown"
              icon={sidebar_icon}
              title={ExportData("KIOSK_BREAKDOWN")}
              fontIcon="bi-app-indicator"
            />
          )}
        </AsideMenuItemWithSub>
      )}
      {Permission(data, fetchPermissionId(data, config.access.RESELLER), 1) && (
        <AsideMenuItem
          to="/reseller"
          icon={sidebar_icon}
          title={ExportData("RESELLER")}
          fontIcon="bi-app-indicator"
        />
      )}
      {Permission(
        data,
        fetchPermissionId(data, config.access.SERVICEPLAN),
        1
      ) && (
        <AsideMenuItem
          to="/serviceplan"
          icon={sidebar_icon}
          title={ExportData("SERVICEPLAN")}
          fontIcon="bi-app-indicator"
        />
      )}
      {Permission(
        data,
        fetchPermissionId(data, config.access.KIOSKSUBSCRIPTION),
        1
      ) && (
        <AsideMenuItem
          to="/subscription"
          icon={sidebar_icon}
          title={ExportData("KIOSKSUBSCRIPTION")}
          fontIcon="bi-app-indicator"
        />
      )}
      {(Permission(data, fetchPermissionId(data, config.access.AUTO), 1) ||
        Permission(
          data,
          fetchPermissionId(data, config.access.MANUALLY),
          1
        )) && (
        <AsideMenuItemWithSub
          to="/payment"
          icon={UsersIcon}
          title={ExportData("PAYMENT")}
          fontIcon="bi-app-indicator"
        >
          {Permission(data, fetchPermissionId(data, config.access.AUTO), 1) && (
            <AsideMenuItem
              to="/payment/auto"
              icon={sidebar_icon}
              title={ExportData("AUTO")}
              fontIcon="bi-app-indicator"
            />
          )}
          {Permission(
            data,
            fetchPermissionId(data, config.access.MANUALLY),
            1
          ) && (
            <AsideMenuItem
              to="/payment/manually"
              icon={sidebar_icon}
              title={ExportData("MANUALLY")}
              fontIcon="bi-app-indicator"
            />
          )}
        </AsideMenuItemWithSub>
      )}
      {Permission(
        data,
        fetchPermissionId(data, config.access.KIOSKCONFIGURATION),
        1
      ) && (
        <AsideMenuItem
          to="/kioskconfiguration"
          icon={sidebar_icon}
          title={ExportData("KIOSK_CONFIGURATION")}
          fontIcon="bi-app-indicator"
        />
      )}
    </>
  );
}
