import { ActionTypes } from "../../Constant/ActionType";
// role, id, page, per_page, sortBy, sort, keyword, status,
const initState = {
    Users: {},
    UserUrl: {
        role: null,
        id: null,
        page: 1,
        per_page: 10,
        sortBy: null,
        sort: null,
        keyword: "",
        status: null,
        country: null,
        hotel: null
    },
    SelectedUser: [],
    SelectAllUser: false,
    RoleDropDown: [],
    SingleUser: {},
    Country: [],
    Province: [],
    UserFilter: {},
    HotelForStaff: []
};
const UserReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetUsers:
            return {
                ...state,
                Users: payload,
            };
        case ActionTypes.SetUserUrl:
            return {
                ...state,
                UserUrl: payload,
            };
        case ActionTypes.SetSelectedUser:
            return {
                ...state,
                SelectedUser: payload,
            };
        case ActionTypes.SetSelectAllUser:
            return {
                ...state,
                SelectAllUser: payload,
            };
        case ActionTypes.GetRoleForUser:
            return {
                ...state,
                RoleDropDown: payload,
            };
        case ActionTypes.SetSingleUser:
            return {
                ...state,
                SingleUser: payload,
            };
        case ActionTypes.GetAllCountry:
            return {
                ...state,
                Country: payload,
            };
        case ActionTypes.GetAllProvince:
            return {
                ...state,
                Province: payload,
            };
        case ActionTypes.SetUserFilter:
            return {
                ...state,
                UserFilter: payload,
            };
        case ActionTypes.GetHotelForUserStaff:
            return {
                ...state,
                HotelForStaff: payload,
            };
        default:
            return state;
    }
};
export default UserReducer;