import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    KioskConfiguration: {},
    KioskConfigurationUrl: {
        id: null,
        page: 1,
        per_page: 10,
        sortBy: null,
        sort: null,
        keyword: "",
    },
    SelectedKioskConfiguration: [],
    SelectAllKioskConfiguration: false,
};
const KioskConfigurationReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetKioskConfiguration:
            return {
                ...state,
                KioskConfiguration: payload,
            };
        case ActionTypes.SetKioskConfigurationUrl:
            return {
                ...state,
                KioskConfigurationUrl: payload,
            };
        case ActionTypes.SetSelectedKioskConfiguration:
            return {
                ...state,
                SelectedKioskConfiguration: payload,
            };
        case ActionTypes.SetSelectAllKioskConfiguration:
            return {
                ...state,
                SelectAllKioskConfiguration: payload,
            };
        default:
            return state;
    }
};
export default KioskConfigurationReducer;


