import { combineReducers } from "redux";
//Storage
import storage from "redux-persist/lib/storage";
//Actions
import { ActionTypes } from "../Constant/ActionType";
//Local Storage
import { GetData } from "../../Api/LocalStorage";

//All Reducer
import AuthReducer from "./AuthReducer/AuthReducer";
import ListingReducer from "./ListingReducer/ListingReducer";
import LoaderReducer from "./LoaderReducer/LoaderReducer";
import RoleReducer from "./RoleReducer/RoleReducer"
import RoleUserReducer from "./RoleUserReducer/RoleUserReducer";
import UserReducer from "./UserReducer/UserReducer";
import ModuleReducer from "./ModuleReducer/ModuleReducer";
import HotelChainReducer from "./HotelChainReducer/HotelChainReducer";
import HotelReducer from "./HotelReducer/HotelReducer";
import OrderReducer from "./OrderReducer/OrderReducer";
import KioskReducer from "./KioskReducer/KioskReducer"
import KioskBreakDownReducer from "./KioskBreakDownReducer/KioskBreakDownReducer";
import RMAReducer from "./RMAReducer/RMAReducer";
import ResellerReducer from "./ResellerReducer/ResellerReducer";
import ServicePlanReducer from "./ServicePlanReducer/ServicePlanReducer";
import KioskSubscriptionReducer from "./KioskSubscriptionReducer/KioskSubscriptionReducer";
import KioskConfigurationReducer from "./KioskConfigurationReducer/KioskConfigurationReducer";

//Combine All Reducers
const AppReducer = combineReducers({
  AuthReducer,
  LoaderReducer,
  RoleReducer,
  RoleUserReducer,
  UserReducer,
  ModuleReducer,
  HotelChainReducer,
  HotelReducer,
  OrderReducer,
  KioskReducer,
  KioskBreakDownReducer,
  RMAReducer,
  ResellerReducer,
  ListingReducer,
  ServicePlanReducer,
  KioskSubscriptionReducer,
  KioskConfigurationReducer
});

const RootReducer = (state, { type, payload }) => {
  // if (GetData("data")) {
  // switch (type) {
  //   case ActionTypes.GetSetLogOut: {
  //     storage.removeItem("persist:Kiosk-Manufacture-Portal");
  //     return AppReducer(undefined, { type, payload });
  //   }
  // }
  return AppReducer(state, { type, payload });
  // } else {
  //   storage.removeItem("persist:Kiosk-Manufacture-Portal");
  //   return AppReducer(undefined, { type, payload });
  // }
};
export default RootReducer;
