import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    Kiosk: {},
    KioskUrl: {
        id: null,
        page: "",
        per_page: "",
        sortBy: null,
        sort: null,
        keyword: "",
    },
    SelectedKiosk: [],
    SelectAllKiosk: false,
    KioskStages: [],
    KioskFilter: {
        
    }
};
const KioskReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetKiosk:
            return {
                ...state,
                Kiosk: payload,
            };
        case ActionTypes.SetKioskUrl:
            return {
                ...state,
                KioskUrl: payload,
            };
        case ActionTypes.SetSelectedKiosk:
            return {
                ...state,
                SelectedKiosk: payload,
            };
        case ActionTypes.SetSelectAllKiosk:
            return {
                ...state,
                SelectAllKiosk: payload,
            };
        case ActionTypes.SetKioskStages:
            return {
                ...state,
                KioskStages: payload,
            };
        case ActionTypes.SetKioskFilter:
            return {
                ...state,
                KioskFilter: payload,
            };
        default:
            return state;
    }
};
export default KioskReducer;
