import React from "react";
import { Outlet } from "react-router-dom";
import { Logo } from "../../../Assets";

const NonAuthLayout = () => {
  return (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <a href="#" className="mb-12">
          <img alt="Logo" src={Logo} className="h-45px" />
        </a>
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default NonAuthLayout;
