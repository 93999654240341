import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    KioskBreakdown: {},
    KioskBreakdownUrl: {
        id: null,
        page: "",
        per_page: "",
        sortBy: null,
        sort: null,
        keyword: "",
    },
    SelectedKioskBreakdown: [],
    SelectAllKioskBreakdown: false,
    KioskForBreakdown: []
};
const KioskBreakDownReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetKioskBreakDown:
            return {
                ...state,
                KioskBreakdown: payload,
            };
        case ActionTypes.SetKioskBreakDownUrl:
            return {
                ...state,
                KioskBreakdownUrl: payload,
            };
        case ActionTypes.SetSelectedKioskBreakDown:
            return {
                ...state,
                SelectedKioskBreakdown: payload,
            };
        case ActionTypes.SetSelectAllKioskBreakDown:
            return {
                ...state,
                SelectAllKioskBreakdown: payload,
            };
        case ActionTypes.SetKioskForKioskBreakDown:
            return {
                ...state,
                KioskForBreakdown: payload,
            };
        default:
            return state;
    }
};
export default KioskBreakDownReducer;
