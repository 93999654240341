//Avatar
export const Avatar = require("./Media/Avatar/avatar.jpg");
export const Correct = require("./Media/Avatar/correct.png");

//Flags
export const EngishLanguage = require("./Media/Flags/united-states.svg").default;
export const SpanishLanguage = require("./Media/Flags/spain.svg").default;
export const FrenchLanguage = require("./Media/Flags/france.svg").default;

//Sidebar
export const DashboardIcon = require("./Media/Sidebar/dashboard.svg").default;
export const UsersIcon = require("./Media/Sidebar/users.svg").default;

//Search
export const SearchIcon = require("./Media/Icons/search.svg").default;
//Plus
export const PlusIcon = require("./Media/Icons/plus.svg").default;
//Filter
export const FilterIcon = require("./Media/Icons/filter.svg").default;
//Sidebar Arrow
export const Sidebar_Arrow = require("./Media/Icons/sidebar_arrow.svg").default;
//sidebar icon
export const sidebar_icon = require("./Media/Icons/sidebar_icon.svg").default;
//logo
export const Logo = require("./Media/Logos/logo.png");
//edit
export const { Edit, Delete, UpArrow, FILTER_ICON, View, Export_Icon, aside_mobile, scroll_top, drop_file, cross, file_success, file_fail, upload_file, download, calender } = require("./Media/AllSvg");
//sort up arrow
export const SortUpArrow = require("./Media/Icons/up_arrow.svg").default;
//sort down arrow 
export const SortDownArrow = require("./Media/Icons/down_arrow.svg").default;
//role
export const Role = require("./Media/Icons/role.svg").default;
