import { useNavigate } from "react-router-dom";
import { Languages } from "./Languages";
import { useSelector, useDispatch } from "react-redux";
import { AuthUser } from "../../../../Redux/Action/AuthAction/AuthAction";

const HeaderUserMenu = () => {
  //State
  const currentUser = useSelector((state) => state.AuthReducer.Auth);
  const ln = useSelector((state) => state.AuthReducer.LanguageData);

  //other
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //log out
  const logout = () => {
    dispatch(AuthUser({}));
    navigate("/");
    localStorage.clear();
    sessionStorage.clear();
  };
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          {/* <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={Avatar} />
          </div> */}
          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {currentUser?.user?.first_name} {currentUser?.user?.last_name}
            </div>
            <p className="fw-bold text-muted text-hover-primary fs-7">
              {currentUser?.user?.email}
            </p>
          </div>
        </div>
      </div>
      {/* <div className="separator my-2"></div>
      <div className="menu-item px-5">
        <Link to={"/crafted/pages/profile"} className="menu-link px-5">
          {ln?.MY_PROFILE || "My Profile"}
        </Link>
      </div> */}
      <div className="separator my-2"></div>
      <Languages />
      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          {ln?.SIGN_OUT || "Sign Out"}
        </a>
      </div>
    </div>
  );
};
export { HeaderUserMenu };
