import { ActionTypes } from "../../Constant/ActionType";
// role, id, page, per_page, sortBy, sort, keyword, status,
const initState = {
    Users: {},
    RoleUserUrl: {
        role: null,
        id: null,
        page: 1,
        per_page: 10,
        sortBy: null,
        sort: null,
        keyword: "",
        status: null
    },
    SelectedRoleUser: [],
    SelectAllRoleUser: false
};
const RoleUserReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetRoleUsers:
            return {
                ...state,
                Users: payload,
            };
        case ActionTypes.SetRoleUserUrl:
            return {
                ...state,
                RoleUserUrl: payload,
            };
        case ActionTypes.SetSelectedRoleUser:
            return {
                ...state,
                SelectedRoleUser: payload,
            };
        case ActionTypes.SetSelectAllRoleUser:
            return {
                ...state,
                SelectAllRoleUser: payload,
            };
        default:
            return state;
    }
};
export default RoleUserReducer;
