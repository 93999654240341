import { ExportData } from "../../config/language";
const Error = (name, errortype, keyName) => {
  let errorMessage = "";
  //for required
  if (errortype == "required") {
    errorMessage = `${ExportData(keyName)} ${ExportData("IS_REQUIRED")}`;
  }
  //for email
  if (name == "email" && errortype == "pattern") {
    errorMessage = ExportData("PLEASE_ENTER_A_VALID_EMAIL");
  }
  //for minlength
  if (errortype == "minLength") {
    if (name == "mobile_number") {
      errorMessage = ExportData("TELEPHONE_MUST_HAVE_SIX_DIGITS_LONG");
    }
  }
  //for number
  if (name == "number" && errortype == "pattern") {
    errorMessage = `${ExportData("ENTER")} ${name} ${ExportData("ONLY")}`;
  }

  return errorMessage;
};

export default Error;
