import { ActionTypes } from "../../Constant/ActionType";
const initState = {
  Auth: {},
  Language: "english",
  LanguageData: {}
};

const AuthReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case ActionTypes.AuthUser:
      return {
        ...state,
        Auth: payload,
      };
    case ActionTypes.GetSetLogOut:
      return {
        ...state,
        Auth: payload,
      };
    case ActionTypes.SetLanguage:
      return {
        ...state,
        Language: payload,
      };
    case ActionTypes.SetLanguageData:
      return {
        ...state,
        LanguageData: payload,
      };
    default:
      return state;
  }
};
export default AuthReducer;
