/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { ExportData } from "../../../config/language";
import { MenuComponent } from "../../../_metronic/assets/ts/components";

const DashboardWrapper = () => {
  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);
  return <div>{ExportData("DASHBOARD")}</div>;
};

export { DashboardWrapper };
