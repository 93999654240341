import store from "../Redux/Store/Store"
export const ConvertObjToPayload = (body, payload, flag) => {
    let obj = {}
    for (let x in body) {
        if (flag) {
            if (!payload.includes(x)) obj[x] = body[x] || null
        } else {
            if (payload.includes(x)) obj[x] = body[x] || null
        }
    }
    return obj;
}
export const RemoveNullPayload = (data) => {
    Object.keys(data).map((key) => !data[key] ? data[key] = null : data[key])
    return data
}

export const ConvertObjToInt = (body, payload) => {
    let obj = {}
    for (let x in body) {
        if (payload.includes(x)) {
            obj[x] = +body[x] || null
        }
    }
    return obj;
}
export const returnKeyValueObject = (keys, dataJson) => {
    let array = [];
    for (var key in dataJson) {
        if (dataJson.hasOwnProperty(key) && keys.includes(key)) {
            array.push({
                key: key,
                value: dataJson[key],
            });
        }
    }
    return array;
};
export const backEndMessages = (message) => {
    const lang = store.getState().AuthReducer.LanguageData;
    return lang[message];
}
export const backEndLabel = (message) => {
    const lang = store.getState().AuthReducer.LanguageData;
    return lang[message];
}
export const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};
export const randomId = () => Math.random().toString(36).replace('0.', "ID" || '');
export const getFullDate = (date) => {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;
    return today
}
export const getDates = (data) => {
    var today = new Date(data);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + "/" + dd;
    return today;
};

export const getMonthName = (data) => {
    switch (data) {
        case 1:
            return "JANUARY";
        case 2:
            return "FEBRUARY"
        case 3:
            return "MARCH"
        case 4:
            return "APRIL"
        case 5:
            return "MAY"
        case 6:
            return "JUNE"
        case 7:
            return "JULY"
        case 8:
            return "AUGUST"
        case 9:
            return "SEPTEMBER"
        case 10:
            return "OCTOBER"
        case 11:
            return "NOVEMBER"
        case 12:
            return "DECEMBER"
        default:
            break;
    }
};