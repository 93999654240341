import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    ServicePlan: {},
    ServicePlanUrl: {
        id: null,
        page: 1,
        per_page: 10,
        sortBy: null,
        sort: null,
        keyword: "",
    },
    SelectedServicePlan: [],
    SelectAllServicePlan: false,
};
const ServicePlanReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetServicePlan:
            return {
                ...state,
                ServicePlan: payload,
            };
        case ActionTypes.SetServicePlanUrl:
            return {
                ...state,
                ServicePlanUrl: payload,
            };
        case ActionTypes.SetSelectedServicePlan:
            return {
                ...state,
                SelectedServicePlan: payload,
            };
        case ActionTypes.SetSelectAllServicePlan:
            return {
                ...state,
                SelectAllServicePlan: payload,
            };
        default:
            return state;
    }
};
export default ServicePlanReducer;