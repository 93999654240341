import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    Roles: [],
    RoleType: [],
    RoleUrl: {
        sortBy: null,
        sort: null,
        keyword: "",
        status: null
    },
    SelectedRole: [],
    SelectAllRoles: false
};
const RoleReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetRole:
            return {
                ...state,
                Roles: payload,
            };
        case ActionTypes.SetRoleType:
            return {
                ...state,
                RoleType: payload,
            };
        case ActionTypes.SetRoleUrl:
            return {
                ...state,
                RoleUrl: payload,
            };
        case ActionTypes.SetSelectedRole:
            return {
                ...state,
                SelectedRole: payload,
            };
        case ActionTypes.SetSelectAllRole:
            return {
                ...state,
                SelectAllRoles: payload,
            };
        default:
            return state;
    }
};
export default RoleReducer;
