import clsx from "clsx";
import {
  EngishLanguage,
  SpanishLanguage,
  FrenchLanguage,
} from "../../../../Assets";
import Api from "../../../../Api/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  SetLanguage,
  SetLanguageData,
} from "../../../../Redux/Action/AuthAction/AuthAction";
import toast from "react-hot-toast";
import { backEndMessages } from "../../../../Helper/common";
const languages = [
  {
    lang: "en",
    name: "english",
    flag: EngishLanguage,
  },
  {
    lang: "es",
    name: "spanish",
    flag: SpanishLanguage,
  },
  {
    lang: "fr",
    name: "french",
    flag: FrenchLanguage,
  },
];
const Languages = () => {
  //state
  const currentLang = useSelector((state) => state.AuthReducer.Language);
  //other
  const dispatch = useDispatch();
  const currentLanguage = languages.find((x) => x.name === currentLang);
  const CallLanguageMethod = async (ln) => {
    const response = await Api(
      "GET",
      `master/get/translated-words?column=${ln}`
    );
    if (response.data.status) {
      dispatch(SetLanguage(ln));
      dispatch(SetLanguageData(response.data.data));
      window.location.reload();
    } else {
      console.log("error", response);
      toast.error(backEndMessages(response.data.message));
    }
  };
  return (
    <div
      className="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="bottom"
    >
      <a href="#" className="menu-link px-5">
        <span className="menu-title position-relative">
          Language
          <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
            {currentLanguage?.name}{" "}
            <img
              className="w-15px h-15px rounded-1 ms-2"
              src={currentLanguage?.flag}
              alt="metronic"
            />
          </span>
        </span>
      </a>
      <div className="menu-sub menu-sub-dropdown w-175px py-4">
        {languages.map((l) => (
          <div
            className="menu-item px-3"
            key={l.lang}
            onClick={() => {
              CallLanguageMethod(l.name);
            }}
          >
            <a
              href="#"
              className={clsx("menu-link d-flex px-5", {
                active: l.lanameng === currentLang,
              })}
            >
              <span className="symbol symbol-20px me-4">
                <img className="rounded-1" src={l.flag} alt="metronic" />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
export { Languages };
