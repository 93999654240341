import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    Hotels: {},
    HotelsUrl: {
        role: null,
        id: null,
        page: 1,
        per_page: 10,
        sortBy: null,
        sort: null,
        keyword: "",
        status: null,
        country: null
    },
    SelectedHotel: [],
    SelectAllHotel: false,
    HotelUser: [],
    HotelChain: [],
    Reseller: [],
    Hotel: {},
    propertyType: [],
    Category: [],
    Segment: [],
    HotelFilter: { room_count: 0 }
};
const HotelReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetHotels:
            return {
                ...state,
                Hotels: payload,
            };
        case ActionTypes.SetHotelsUrl:
            return {
                ...state,
                HotelsUrl: payload,
            };
        case ActionTypes.SetSelectedHotel:
            return {
                ...state,
                SelectedHotel: payload,
            };
        case ActionTypes.SetSelectAllHotel:
            return {
                ...state,
                SelectAllHotel: payload,
            };
        case ActionTypes.SetHotelUserForHotel:
            return {
                ...state,
                HotelUser: payload,
            };
        case ActionTypes.SetHotelChainForHotel:
            return {
                ...state,
                HotelChain: payload,
            };
        case ActionTypes.SetResellerForHotel:
            return {
                ...state,
                Reseller: payload,
            };
        case ActionTypes.SetSingleHotel:
            return {
                ...state,
                Hotel: payload,
            };
        case ActionTypes.SetPropertyTypeForHotel:
            return {
                ...state,
                propertyType: payload,
            };
        case ActionTypes.SetCategoryForHotel:
            return {
                ...state,
                Category: payload,
            };
        case ActionTypes.SetSegmentForHotel:
            return {
                ...state,
                Segment: payload,
            };
        case ActionTypes.SetHotelFilter:
            return {
                ...state,
                HotelFilter: payload,
            };
        default:
            return state;
    }
};
export default HotelReducer;
