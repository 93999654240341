import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    Country: [],
    Province: [],
    Roles: [],
    HotelChainUsers: [],
    HotelUsers: [],
    HotelChains: [],
    Hotels: [],
    OrderUsers: [],
    KioskReseller: [],
    KioskHotels: [],
    RMAOpenBy: [],
    RMAClients: [],
    RMAHotels: [],
    RMAKiosks: [],
    KioskUsers: [],
    KioskType: [],
    KioskModel: [],
    HotelRoomLocks: [],
    KioskRoomLocks: []
};
const ListingReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.GetAllCountry:
            return {
                ...state,
                Country: payload,
            };
        case ActionTypes.GetAllProvince:
            return {
                ...state,
                Province: payload,
            };
        case ActionTypes.GetRoleForUser:
            return {
                ...state,
                Roles: payload,
            };
        case ActionTypes.GetHotelChainUsers:
            return {
                ...state,
                HotelChainUsers: payload,
            };
        case ActionTypes.GetHotelUsers:
            return {
                ...state,
                HotelUsers: payload,
            };
        case ActionTypes.GetHotelChainsForHotel:
            return {
                ...state,
                HotelChains: payload,
            };
        case ActionTypes.GetHotelForHotelStaff:
            return {
                ...state,
                Hotels: payload,
            };
        case ActionTypes.GetCustomerForOrder:
            return {
                ...state,
                OrderUsers: payload,
            };
        case ActionTypes.GetResellerForKiosks:
            return {
                ...state,
                KioskReseller: payload,
            };
        case ActionTypes.GetHotelForKiosks:
            return {
                ...state,
                KioskHotels: payload,
            };
        case ActionTypes.GetRMAOpenBy:
            return {
                ...state,
                RMAOpenBy: payload,
            };
        case ActionTypes.GetRMAClient:
            return {
                ...state,
                RMAClients: payload,
            };
        case ActionTypes.GetRMAHotels:
            return {
                ...state,
                RMAHotels: payload,
            };
        case ActionTypes.GetRMAKiosks:
            return {
                ...state,
                RMAKiosks: payload,
            };
        case ActionTypes.GetKioskUsers:
            return {
                ...state,
                KioskUsers: payload,
            };
        case ActionTypes.GetKioskType:
            return {
                ...state,
                KioskType: payload,
            };
        case ActionTypes.GetKioskModel:
            return {
                ...state,
                KioskModel: payload,
            };
        case ActionTypes.GetRoomLocksForHotel:
            return {
                ...state,
                HotelRoomLocks: payload,
            };
        case ActionTypes.GetRoomLocksForKiosk:
            return {
                ...state,
                KioskRoomLocks: payload,
            };
        default:
            return state;
    }
};
export default ListingReducer;


