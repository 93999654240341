import { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { useSelector } from "react-redux";
import { fetchPermissionId, Permission } from "../../Helper/Permission";
import { config } from "../../config/config";

const PrivateRoutes = () => {
  //Roles
  const RolesPage = lazy(() => import("../../Components/Private/Roles"));
  const AddRolePage = lazy(() =>
    import("../../Components/Private/Roles/AddRole")
  );
  const EditRolePage = lazy(() =>
    import("../../Components/Private/Roles/EditRole")
  );
  const ViewRolePage = lazy(() =>
    import("../../Components/Private/Roles/ViewRole")
  );
  //Users
  const UserPage = lazy(() => import("../../Components/Private/Users"));
  const AddUserPage = lazy(() =>
    import("../../Components/Private/Users/AddUser")
  );
  const EditUserPage = lazy(() =>
    import("../../Components/Private/Users/EditUser")
  );
  const ViewUserPage = lazy(() =>
    import("../../Components/Private/Users/ViewUser")
  );

  //hotel mangement

  //hotel chain
  const HotelChainPage = lazy(() =>
    import("../../Components/Private/HotelChain")
  );
  const AddHotelChainPage = lazy(() =>
    import("../../Components/Private/HotelChain/AddChain")
  );
  const EditHotelChainPage = lazy(() =>
    import("../../Components/Private/HotelChain/EditChain")
  );
  const ViewHotelChainPage = lazy(() =>
    import("../../Components/Private/HotelChain/ViewChain")
  );
  //hotel
  const HotelPage = lazy(() => import("../../Components/Private/Hotels"));
  const AddHotelPage = lazy(() =>
    import("../../Components/Private/Hotels/AddHotel")
  );
  const EditHotelPage = lazy(() =>
    import("../../Components/Private/Hotels/EditHotel")
  );
  const ViewHotelPage = lazy(() =>
    import("../../Components/Private/Hotels/ViewHotel")
  );

  //order management

  //orders
  const OrdersPage = lazy(() => import("../../Components/Private/Orders"));
  const AddOrderPage = lazy(() =>
    import("../../Components/Private/Orders/AddOrder")
  );
  const EditOrderPage = lazy(() =>
    import("../../Components/Private/Orders/EditOrder")
  );
  const ViewOrderPage = lazy(() =>
    import("../../Components/Private/Orders/ViewOrder")
  );

  //kiosk
  const KioskPage = lazy(() => import("../../Components/Private/Kiosk"));
  const AddKioskPage = lazy(() =>
    import("../../Components/Private/Kiosk/AddKiosk")
  );
  const EditKioskPage = lazy(() =>
    import("../../Components/Private/Kiosk/EditKiosk")
  );
  const ViewKioskPage = lazy(() =>
    import("../../Components/Private/Kiosk/ViewKiosk")
  );

  //kiosk break down
  const KioskBreakDownPage = lazy(() =>
    import("../../Components/Private/KioskBreakDown")
  );
  const AddKioskBreakDownPage = lazy(() =>
    import("../../Components/Private/KioskBreakDown/AddKioskBreakDown")
  );
  const EditKioskBreakDownPage = lazy(() =>
    import("../../Components/Private/KioskBreakDown/EditKioskBreakDown")
  );
  const ViewKioskBreakDownPage = lazy(() =>
    import("../../Components/Private/KioskBreakDown/ViewKioskBreakDown")
  );

  //RMA
  const RMAPage = lazy(() => import("../../Components/Private/RMA"));
  const AddRMAPage = lazy(() => import("../../Components/Private/RMA/AddRMA"));
  const EditRMAPage = lazy(() =>
    import("../../Components/Private/RMA/EditRMA")
  );
  const ViewRMAPage = lazy(() =>
    import("../../Components/Private/RMA/ViewRMA")
  );

  //Reseller
  const ResellerPage = lazy(() => import("../../Components/Private/Reseller"));
  const AddResellerPage = lazy(() =>
    import("../../Components/Private/Reseller/AddReseller")
  );
  const EditResellerPage = lazy(() =>
    import("../../Components/Private/Reseller/EditReseller")
  );
  const ViewResellerPage = lazy(() =>
    import("../../Components/Private/Reseller/ViewReseller")
  );

  //Service Plan
  const ServicePlanPage = lazy(() =>
    import("../../Components/Private/ServicePlan")
  );
  const AddServicePlanPage = lazy(() =>
    import("../../Components/Private/ServicePlan/AddServicePlan")
  );
  const EditServicePlanPage = lazy(() =>
    import("../../Components/Private/ServicePlan/EditServicePlan")
  );
  const ViewServicePlanPage = lazy(() =>
    import("../../Components/Private/ServicePlan/ViewServicePlan")
  );

  //Kiosk Subscription
  const KioskSubscriptionPage = lazy(() =>
    import("../../Components/Private/KioskSubscription")
  );
  const AddKioskSubscriptionPage = lazy(() =>
    import("../../Components/Private/KioskSubscription/AddKioskSubscription")
  );
  const EditKioskSubscriptionPage = lazy(() =>
    import("../../Components/Private/KioskSubscription/EditKioskSubscription")
  );
  const ViewKioskSubscriptionPage = lazy(() =>
    import("../../Components/Private/KioskSubscription/ViewKioskSubscription")
  );

  //payment
  const AutoPayment = lazy(() =>
    import("../../Components/Private/AutoPayment")
  );
  const ManualPayment = lazy(() =>
    import("../../Components/Private/ManuallyPayment")
  );

  //kiosk configuration
  const KioskConfiguration = lazy(() =>
    import("../../Components/Private/KioskConfiguration")
  );
  const AddKioskConfiguration = lazy(() =>
    import("../../Components/Private/KioskConfiguration/AddKioskConfiguration")
  );
  const EditKioskConfiguration = lazy(() =>
    import("../../Components/Private/KioskConfiguration/EditKioskConfiguration")
  );
  const ViewKioskConfiguration = lazy(() =>
    import("../../Components/Private/KioskConfiguration/ViewKioskConfiguration")
  );
  //page not found
  const PageNotFound = lazy(() => import("../../Helper/PageNotFound"));

  const data = useSelector((state) => state.AuthReducer.Auth.permissions);

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="dashboard" element={<DashboardWrapper />} />
        {Permission(data, fetchPermissionId(data, config.access.USERS), 1) && (
          <Route
            path="users"
            element={
              <SuspensedView>
                <UserPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.USERS), 2) && (
          <Route
            path="users/add"
            element={
              <SuspensedView>
                <AddUserPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.USERS), 3) && (
          <Route
            path="users/edit"
            element={
              <SuspensedView>
                <EditUserPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="users/view"
          element={
            <SuspensedView>
              <ViewUserPage />
            </SuspensedView>
          }
        />
        {Permission(data, fetchPermissionId(data, config.access.ROLES), 1) && (
          <Route
            path="roles"
            element={
              <SuspensedView>
                <RolesPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.ROLES), 2) && (
          <Route
            path="roles/add"
            element={
              <SuspensedView>
                <AddRolePage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.ROLES), 3) && (
          <Route
            path="roles/edit"
            element={
              <SuspensedView>
                <EditRolePage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="roles/view"
          element={
            <SuspensedView>
              <ViewRolePage />
            </SuspensedView>
          }
        />
        {Permission(
          data,
          fetchPermissionId(data, config.access.HOTELCHAIN),
          1
        ) && (
          <Route
            path="chains"
            element={
              <SuspensedView>
                <HotelChainPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.HOTELCHAIN),
          2
        ) && (
          <Route
            path="chains/add"
            element={
              <SuspensedView>
                <AddHotelChainPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.HOTELCHAIN),
          3
        ) && (
          <Route
            path="chains/edit"
            element={
              <SuspensedView>
                <EditHotelChainPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="chains/view"
          element={
            <SuspensedView>
              <ViewHotelChainPage />
            </SuspensedView>
          }
        />
        {Permission(data, fetchPermissionId(data, config.access.HOTEL), 1) && (
          <Route
            path="hotels"
            element={
              <SuspensedView>
                <HotelPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.HOTEL), 2) && (
          <Route
            path="hotels/add"
            element={
              <SuspensedView>
                <AddHotelPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.HOTEL), 3) && (
          <Route
            path="hotels/edit"
            element={
              <SuspensedView>
                <EditHotelPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="hotels/view"
          element={
            <SuspensedView>
              <ViewHotelPage />
            </SuspensedView>
          }
        />
        {Permission(
          data,
          fetchPermissionId(data, config.access.HOTELSTAFF),
          1
        ) && (
          <Route
            path="staff"
            element={
              <SuspensedView>
                <UserPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.HOTELSTAFF),
          2
        ) && (
          <Route
            path="staff/add"
            element={
              <SuspensedView>
                <AddUserPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.HOTELSTAFF),
          3
        ) && (
          <Route
            path="staff/edit"
            element={
              <SuspensedView>
                <EditUserPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="staff/view"
          element={
            <SuspensedView>
              <ViewUserPage />
            </SuspensedView>
          }
        />
        {Permission(data, fetchPermissionId(data, config.access.Orders), 1) && (
          <Route
            path="orders"
            element={
              <SuspensedView>
                <OrdersPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.Orders), 2) && (
          <Route
            path="orders/add"
            element={
              <SuspensedView>
                <AddOrderPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.Orders), 3) && (
          <Route
            path="orders/edit"
            element={
              <SuspensedView>
                <EditOrderPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="orders/view"
          element={
            <SuspensedView>
              <ViewOrderPage />
            </SuspensedView>
          }
        />
        {Permission(data, fetchPermissionId(data, config.access.KIOSK), 1) && (
          <Route
            path="kiosks"
            element={
              <SuspensedView>
                <KioskPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.KIOSK), 2) && (
          <Route
            path="kiosks/add"
            element={
              <SuspensedView>
                <AddKioskPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.KIOSK), 3) && (
          <Route
            path="kiosks/edit"
            element={
              <SuspensedView>
                <EditKioskPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="kiosks/view"
          element={
            <SuspensedView>
              <ViewKioskPage />
            </SuspensedView>
          }
        />
        {Permission(
          data,
          fetchPermissionId(data, config.access.KIOSKBREAKDOWN),
          1
        ) && (
          <Route
            path="kioskbreakdown"
            element={
              <SuspensedView>
                <KioskBreakDownPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.KIOSKBREAKDOWN),
          2
        ) && (
          <Route
            path="kioskbreakdown/add"
            element={
              <SuspensedView>
                <AddKioskBreakDownPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.KIOSKBREAKDOWN),
          3
        ) && (
          <Route
            path="kioskbreakdown/edit"
            element={
              <SuspensedView>
                <EditKioskBreakDownPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="kioskbreakdown/view"
          element={
            <SuspensedView>
              <ViewKioskBreakDownPage />
            </SuspensedView>
          }
        />
        {Permission(data, fetchPermissionId(data, config.access.RMA), 1) && (
          <Route
            path="rma"
            element={
              <SuspensedView>
                <RMAPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.RMA), 2) && (
          <Route
            path="rma/add"
            element={
              <SuspensedView>
                <AddRMAPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(data, fetchPermissionId(data, config.access.RMA), 3) && (
          <Route
            path="rma/edit"
            element={
              <SuspensedView>
                <EditRMAPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="rma/view"
          element={
            <SuspensedView>
              <ViewRMAPage />
            </SuspensedView>
          }
        />
        {Permission(
          data,
          fetchPermissionId(data, config.access.RESELLER),
          1
        ) && (
          <Route
            path="reseller"
            element={
              <SuspensedView>
                <ResellerPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.RESELLER),
          2
        ) && (
          <Route
            path="reseller/add"
            element={
              <SuspensedView>
                <AddResellerPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.RESELLER),
          3
        ) && (
          <Route
            path="reseller/edit"
            element={
              <SuspensedView>
                <EditResellerPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="reseller/view"
          element={
            <SuspensedView>
              <ViewResellerPage />
            </SuspensedView>
          }
        />
        {Permission(
          data,
          fetchPermissionId(data, config.access.SERVICEPLAN),
          1
        ) && (
          <Route
            path="serviceplan"
            element={
              <SuspensedView>
                <ServicePlanPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.SERVICEPLAN),
          2
        ) && (
          <Route
            path="serviceplan/add"
            element={
              <SuspensedView>
                <AddServicePlanPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.SERVICEPLAN),
          3
        ) && (
          <Route
            path="serviceplan/edit"
            element={
              <SuspensedView>
                <EditServicePlanPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="serviceplan/view"
          element={
            <SuspensedView>
              <ViewServicePlanPage />
            </SuspensedView>
          }
        />
        {Permission(
          data,
          fetchPermissionId(data, config.access.KIOSKSUBSCRIPTION),
          1
        ) && (
          <Route
            path="subscription"
            element={
              <SuspensedView>
                <KioskSubscriptionPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.KIOSKSUBSCRIPTION),
          2
        ) && (
          <Route
            path="subscription/add"
            element={
              <SuspensedView>
                <AddKioskSubscriptionPage />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.KIOSKSUBSCRIPTION),
          3
        ) && (
          <Route
            path="subscription/edit"
            element={
              <SuspensedView>
                <EditKioskSubscriptionPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="subscription/view"
          element={
            <SuspensedView>
              <ViewKioskSubscriptionPage />
            </SuspensedView>
          }
        />
        {Permission(data, fetchPermissionId(data, config.access.AUTO), 1) && (
          <Route
            path="payment/auto"
            element={
              <SuspensedView>
                <AutoPayment />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.MANUALLY),
          1
        ) && (
          <Route
            path="payment/manually"
            element={
              <SuspensedView>
                <ManualPayment />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.KIOSKCONFIGURATION),
          1
        ) && (
          <Route
            path="kioskconfiguration"
            element={
              <SuspensedView>
                <KioskConfiguration />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.KIOSKCONFIGURATION),
          2
        ) && (
          <Route
            path="kioskconfiguration/add"
            element={
              <SuspensedView>
                <AddKioskConfiguration />
              </SuspensedView>
            }
          />
        )}
        {Permission(
          data,
          fetchPermissionId(data, config.access.KIOSKCONFIGURATION),
          3
        ) && (
          <Route
            path="kioskconfiguration/edit"
            element={
              <SuspensedView>
                <EditKioskConfiguration />
              </SuspensedView>
            }
          />
        )}
        <Route
          path="kioskconfiguration/view"
          element={
            <SuspensedView>
              <ViewKioskConfiguration />
            </SuspensedView>
          }
        />
        <Route
          path="/error/404"
          element={
            <SuspensedView>
              <PageNotFound />
            </SuspensedView>
          }
        />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};
const SuspensedView = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
export { PrivateRoutes };
