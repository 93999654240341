import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
//redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import store, { persistor } from "./Redux/Store/Store";

//css
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";
import "./Assets/Assets/Css/MyStyle.scss";

// import "./Assets/Assets/js/custom.js"
//app.js
import AppRoutes from "./app/routing/AppRoutes";

//if you want to clear your all console log then apply below line
// console.log = () => { };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AppRoutes />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();