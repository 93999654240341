import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    KioskSubscription: {},
    KioskSubscriptionUrl: {
        id: null,
        page: 1,
        per_page: 10,
        sortBy: null,
        sort: null,
        keyword: "",
    },
    SelectedKioskSubscription: [],
    SelectAllKioskSubscription: false,
    KioskForKioskSubcription: [],
    PlanForKiosk: []
};
const KioskSubscriptionReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetKioskSubscription:
            return {
                ...state,
                KioskSubscription: payload,
            };
        case ActionTypes.SetKioskSubsciptionUrl:
            return {
                ...state,
                KioskSubscriptionUrl: payload,
            };
        case ActionTypes.SetSelectedKioskSubscription:
            return {
                ...state,
                SelectedKioskSubscription: payload,
            };
        case ActionTypes.SetSelectAllKioskSubscription:
            return {
                ...state,
                SelectAllKioskSubscription: payload,
            };
        case ActionTypes.SetKioskForKioskSubcription:
            return {
                ...state,
                KioskForKioskSubcription: payload,
            };

        case ActionTypes.SetPlanForKiosk:
            return {
                ...state,
                PlanForKiosk: payload,
            };
        default:
            return state;
    }
};
export default KioskSubscriptionReducer;


