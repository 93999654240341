import {
  Routes,
  Route,
  HashRouter,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { PrivateRoutes } from "./PrivateRoutes";
import { App } from "../App";
import Login from "../../Components/Public/Login";
import NonAuthLayout from "../../_metronic/layout/NonAuth/NonAuthLayout";
import ForgotPassword from "../../Components/Public/ForgotPassword";
import ResetPassword from "../../Components/Public/ResetPassword";
import VerifyEmail from "../../Components/Public/VerifyEmail";

const AppRoutes = () => {
  //State
  const Auth = useSelector((state) => state.AuthReducer.Auth.auth_token);
  return (
    <HashRouter>
      <Routes>
        <Route element={<App />}>
          {!Auth && (
            <>
              <Route element={<NonAuthLayout />}>
                <Route path="/" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/verify/email" element={<VerifyEmail />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
            </>
          )}
          {Auth && (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          )}
        </Route>
      </Routes>
    </HashRouter>
  );
};
export default AppRoutes;
