const lanobj = {
    LOGIN_HEADER_TXT: "Sign In to Kiosk Manufacture Portal",
    EMAIL: "Email",
    PASSWORD: "password",
    FORGOT_PASSWORD: "Forgot Password",
    LOGIN_BTN: "Continue",
    ROLE_NAME: "Role Name",
    DESCRIPTION: "Description",
    ROLE_PERMISSIONS: "Role Permissions",
    CREATE: "Create",
    READ: "Read",
    UPDATE: "Update",
    DELETE: "Delete",
    ALL: "All",
    DISCARD: "Discard",
    FORGOT_SUBLINE_TXT: "Enter your email to reset your password",
    SUBMIT: "Submit",
    CANCEL: "Cancel",
    RESET_HEADER_TXT: "Setup New Password",
    RESET_SUBLINE_TXT: "Already have reset your password",
    SIGN_IN_HERE: "Sign in here",
    RESET_PASSWORD_BOTTOM_TXT: "Use 8 or more characters with a mix of letters, numbers & symbols",
    CONFIRM_PASSWORD: "Confirm Password",
    LOGIN: "Login",
    DASHBOARD: "Dashboard",
    ROLES: "Roles",
    ROLE_TYPE: "Role Type",
    ACTIONS: "Actions",
    ADD_ROLE: "Add Role",
    SEARCH_ROLES: "Search Roles",
    EXPORT: "Export",
    EDIT_ROLE: "Edit Role",
    VIEW_ROLE: "View Role",
    USERS: "Users",
    NAME: "Name",
    ROLE: "Role",
    STATUS: "Status",
    EMAIL_VERIFY: "Email Verify",
    VERIFY: "Verify",
    NOT_VERIFY: "Not Verify",
    ACTIVE: "Active",
    NOT_ACTIVE: "Not Active",
    DEACTIVE: "Deactive",
    NO_DATA_IN_TABLE: "No data available in table",
    FILTER_OPTIONS: "Filter Options",
    FILTER: "Filter",
    RESET: "Reset",
    APPLY: "Apply",
    ADD_USER: "Add User",
    SELECTED: "Selected",
    DELETE_SELECTED: "Delete Selected",
    SEARCH_USERS: "Search Users",
    SELECT: "Select",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    MOBILE_NUMBER: "Mobile Number",
    REGION_PROVINCE: "Region/Province",
    TELEPHONE: "Telephone",
    ADDRESS: "Address",
    COUNTRY: "Country",
    PROVINCE: "Province",
    CITY: "City",
    POSTAL_CODE: "Postal Code",
    EDIT_USER: "Edit User",
    VIEW_USER: "View User",
    HOTEL_CHAIN: "Hotel Chain",
    HOTEL_CHAINS: "Hotel Chains",
    ADD_HOTEL_CHAIN: "Add Hotel Chain",
    SEARCH_HOTEL_CHAIN: "Search Hotel Chain",
    SEARCH_HOTEL: "Search Hotel",
    CHAIN_NAME: "Chain Name",
    EDIT_HOTEL_CHAIN: "Edit Hotel Chain",
    VIEW_HOTEL_CHAIN: "View Hotel Chain",
    HOTELS: "Hotels",
    HOTEL: "Hotel",
    COMPANY_NAME: "Company Name",
    VIEW_HOTEL: "View Hotel",
    ADD_HOTEL: "Add Hotel",
    ROOM_COUNT: "Room Count",
    HOTEL_CONTACT_PERSON: "Hotel Contact Person",
    WEBSITE: "Website",
    LONGITUDE: "Longitude",
    LATITUDE: "Latitude",
    CATEGORY: "Category",
    PROPERTY_TYPE: "Property Type",
    SEGMENT: "Segment",
    HOTEL_LIST: "Hotel List",
    EDIT_HOTEL: "Edit Hotel",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS: "Are you sure you want to delete this",
    YES_DELETE: "Yes, delete!",
    NO_CANCEL: "No, cancel",
    HOTEL_MANAGEMENT: "Hotel Management",
    USER_MANAGEMENT: "User Management",
    HOTEL_STAFF: "Hotel Staff",
    ADD_HOTEL_STAFF: "Add Hotel Staff",
    Edit_HOTEL_STAFF: "Edit Hotel Staff",
    VIEW_HOTEL_STAFF: "View Hotel Staff",
    SEARCH_HOTEL_STAFF: "Search Hotel Staff",
    USER: "User",
    HOTEL_STAFFS: "Hotel Staffs",
    ORDERS_MANAGEMENT: "Orders Management",
    ORDERS: "Orders",
    ADD_ORDER: "Add Order",
    EDIT_ORDER: "Edit Order",
    VIEW_ORDER: "View Order",
    ORDER: "Order",
    Search_ORDERS: "Search Orders",
    QUOTATION_ID: "Quotation Id",
    CUSTOMER_ID: "Customer Id",
    ORDER_TYPE: "Order Type",
    UNIQUE_ORDER_NUMBER: "Unique Order Number",
    QUOTATION: "Quotation",
    PROFORMA_INVOICE: "Proforma Invoice",
    ORDER_SHEET: "Order Sheet",
    DOWN_PAYMENT_BANK_SLIP: "Down Payment Bank Slip",
    COMMERCIAL_INVOICE: "Commercial Invoice",
    MANUFACTURING_SHEET: "Manufacturing Sheet",
    GREATER_THAN: "Greater than",
    LESS_THAN: "Less than",
    IS_REQUIRED: "is required",
    PLEASE_ENTER_A_VALID_EMAIL: "Please enter a valid Email",
    TELEPHONE_MUST_HAVE_SIX_DIGITS_LONG: "Telephone must have 6 digits long",
    PASSWORD_AND_CONFIRM_PASSWORD_MUST_BE_SAME: "Password and Confirm Password must be same",
    PLEASE_ENTER_A_VALID_CONFIRM_PASSWORD: "Please enter a valid Confirm Password",
    NEW_PASSWORD_MUST_BE_EIGHT_CHARACTER_LONG: "New password must be 8 character long",
    PLEASE_ENTER_A_VALI: "Please enter a valid",
    ENTER_A_VALID_PASSWORD: "Enter A Valid Password",
    ENTER: "Enter",
    ONLY: "only",
    ADD: "Add",
    PRICE: "Price",
    KIOSK: "Kiosk",
    SUB_TOTAL: "Sub Total",
    TOTAL_TAX: "Total Tax",
    SHIPPING_COST: "Shipping Cost",
    GRAND_TOTAL: "Grand Total",
    TOTAL_PAID: "Total Paid",
    CUSTOMER_NAME: "Customer Name",
    FILE_SIZE_VALIDATION_MESSAGE: "You should not exceed more then $ MB.",
    NO: "No",
    ADD_KIOSK: "Add Kiosk",
    EDIT_KIOSK: "Edit Kiosk",
    VIEW_KIOSK: "View Kiosk",
    SEARCH_KIOSK: "Search Kiosk",
    TYPE: "Type",
    MODEL: "Model",
    MANUFACTURING_DATE: "Manufacturing Date",
    DELIVERY_DATE: "Delivery Date",
    COMMISSIONING_DATE: "Commissioning Date",
    GUARANTEE_EXPIRE_DATE: "Guarantee Expire Date",
    STAGE: "Stage",
    RESELLER: "Reseller",
    DETAILS: "Details",
    LOGS: "Logs",
    KIOSK_BREAKDOWN: "Kiosk Breakdown",
    ADD_KIOSK_BREAKDOWN: "Add Kiosk Breakdown",
    EDIT_KIOSK_BREAKDOWN: "Edit Kiosk Breakdown",
    VIEW_KIOSK_BREAKDOWN: "View Kiosk Breakdown",
    SEARCH_KIOSK_BREAKDOWN: "Search Kiosk Breakdown",
    KIOSKS: "Kiosks",
    UPDATED_AT: "Updated At",
    UPDATED_BY: "Updated By",
    RMA: "RMA",
    RMAS: "RMAS",
    ADD_RMA: "Add RMA",
    Edit_RMA: "Edit RMA",
    VIEW_RMA: "View RMA",
    SEARCH_RMA: "Search RMA",
    RMA_UNIQUE_REFERENCE: "RMA Unique Reference",
    OPENED_BY: "Opened By",
    IS_ALREADY_PAID: "Is Already Paid",
    CLIENT: "Client",
    DEVICE: "Device",
    SERIAL_NUMBER: "Serial Number",
    CURRENT_STAGE: "Current Stage",
    LOCATION: "Location",
    YES: "Yes",
    REMARKS: "Remarks",
    KIOSK_BREAKDOWNS: "Kiosk Breakdowns",
    RESERVATION_LINK: "Reservation Link",
    NAVIGATION_LINK: "Navigation Link",
    COMPANY_ADDRESS: "Company Address",
    TIN: "Tin",
    COMPANY_DETAILS: "Company Details",
    ALL_DOCUMENTS: "All Documents",
    NO_DOC_REQUIRED: "No documents is required in this module",
    ADD_RESELLER: "Add Reseller",
    EDIT_RESELLER: "Edit Reseller",
    VIEW_RESELLER: "View Reseller",
    SEARCH_RESELLER: "Search Reseller",
    COMMERCIAL_CONDITIONS: "Commercial Conditions",
    COMPLETE_COMPANY_NAME: "Complete Company Name",
    RESELLERS: "Resellers",
    SIGNED_CONTRACT: "Signed Contract",
    ONLY_FOUR_FILES: "Only 4 files are allowed to upload",
    PMS: "PMS",
    ROOM_LOCKS: "Room Locks",
    SERVICEPLAN: "Service Plan",
    KIOSKSUBSCRIPTION: "Kiosk Subscription",
    ADD_SERVICEPLAN: "Add Service Plan",
    EDIT_SERVICEPLAN: "Edit Service Plan",
    VIEW_SERVICEPLAN: "View Service Plan",
    SEARCH_SERVICEPLAN: "Search Service Plan",
    ADD_KIOSKSUBSCRIPTION: "Add Kiosk Subscription",
    EDIT_KIOSKSUBSCRIPTION: "Edit Kiosk Subscription",
    VIEW_KIOSKSUBSCRIPTION: "View Kiosk Subscription",
    SEARCH_KIOSKSUBSCRIPTION: "Search Kiosk Subscription",
    SERVICEPLANS: "Service Plans",
    PLAN_NAME: "Plan Name",
    PLAN_TYPE: "Plan Type",
    DURATION: "Duration",
    KIOSKSUBSCRIPTIONS: "Kiosk Subscriptions",
    PLAN: "Plan",
    ADD_PLAN: "Add Plan",
    MONTHLY: "Monthly",
    YEARLY: "Yearly",
    SELECT: "Select",
    UPLOAD_FILES: "Upload Files",
    DUPLICATE_VALUE_IN_KIOSK: "Duplicate value found in kiosk",
    UNIQUE_KIOSK_IDENTIFIER: "Unique Kiosk Identifier",
    Order_DATE: "Order Date",
    DEADLINE_DATE: "Deadline Date",
    COLOUR: "Colour",
    CLOSING_MONTH_PRICE: "Closing Month Price",
    OPENING_MONTH_PRICE: "Opening Month Price",
    KIOSK_MANAGEMENT: "Kiosk Management",
    KIOSK_IMAGES: "Kiosk Images",
    SELECT_VALUE: "Select Value",
    SELECT_DETAILS: "Select Detail",
    SELECT_PARTS: "Select Part",
    PLAN_START_DATE: "Plan Start Date",
    DECEMBER: "December",
    NOVEMBER: "November",
    OCTOBER: "October",
    SEPTEMBER: "September",
    AUGUST: "August",
    JUNE: "June",
    JULY: "July",
    MAY: "May",
    APRIL: "April",
    MARCH: "March",
    FEBRUARY: "February",
    JANUARY: "January",
    SELECT_MONTH: "Select Month",
    SELECT_ALL: "Select All",
    MANUALLY: "Manually",
    AUTO: "Auto",
    PAYMENT: "Payment",
    SELECT_ALL_MONTHLY_YEARLY: "Select all plans either monthly or yearly",
    START_MONTH_PRICE_TOTAL: "Start Month Price Total",
    CLOSE_MONTH_PRICE_TOTAL: "Close Month Price Total",
    END_DATE: "End date",
    START_DATE: "Start date",
    DUPLICATE_PLAN_FOUND: "Duplicate plan found",
    MONTHS: "Months",
    PAYMENT_TYPE: "Payment Type",
    MANUAL: "Manual",
    OPENING_MONTH: "Opening Month",
    CLOSING_MONTH: "Closing Month",
    KIOSK_CONFIGURATION_JSON_FILE: "Kiosk Configuration(JSON file)",
    ONLY_JSON_FILE_ALLOWED: "Only JSON file allowed",
    UPLOAD_VALID_JSON_FILE: "Upload valid JSON file",
    SEARCH_KIOSK_CONFIGURATION: "Search Kiosk Configuration",
    EDIT_KIOSK_CONFIGURATION: "Edit Kiosk Configuration",
    VIEW_KIOSK_CONFIGURATION: "View Kiosk Configuration",
    KIOSK_CONFIGURATIONS: "Kiosk Configurations",
    CURRENCY: "Currency",
}
export default lanobj;