//Defult props pass when use Input field
// register
// name
// value
// onChange method
// this four field required for use this.....!

//if you use number then add one more field
// type="number"

//import Error file
import Error from "../Error/Error";
const Input = ({
  id,
  register,
  name,
  type,
  placeholder,
  errortype,
  required,
  maxLength,
  minLength,
  pattern,
  style,
  className,
  labelname,
  labelclassName,
  spanclassName,
  defaultValue,
  fronticon,
  icon,
  iconclick,
  disabled,
  accept,
  data,
  errorclassName,
  autoComplete,
  key,
  keyName
}) => {
  //trim the entered value and borderstyle

  const borderstyle = errortype ? "is-invalid" : data ? "is-valid" : "";

  //number pattern
  const numberregex = /^[0-9\b]+$/;

  //email pattern
  const emailregex =
    /^(\s*[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9}\s*)$/;

  //space pattern
  const spaceregex = /(?!^ +$)^.+$/;

  // password pattern
  const passwordregex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  //On Paste Space Check
  const spacepaste = new RegExp("/^s+$/");

  //Not Aollow Special character
  // const specialregex = /[^\w]|_/g;

  //Not Allow Specific Special character
  const specificspecialregex = /^[@\_\-\/]+$/;

  //Lat Long regex
  const latlongregex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/

  //Lowercase
  const lowercaseregex = /[a-z]/

  //IP
  const ipregex = /^(?: [0 - 9]{ 1, 3 } \.){3}[0-9]{1, 3}$/

  // apply pattern for input
  const checkpattern = (patternname) => {
    if (patternname === "email") {
      return emailregex;
    }
    if (patternname === "number") {
      return numberregex;
    }
    if (patternname === "text") {
      return spaceregex;
    }
    if (patternname === "password") {
      return passwordregex;
    }
    if (patternname === "space") {
      return spacepaste;
    }
    if (patternname === "latlong") {
      return latlongregex
    }
    if (patternname === "lowercase") {
      return lowercaseregex
    }
    if (patternname === "ipv4") {
      return ipregex
    }
    if (patternname === "ipv6") {
      return ipregex
    }
    if (patternname === "specificspecial") {
      return specificspecialregex;
    }
  };
  //check type for email and number
  const checktype = (namefield) => {
    if (namefield === "email") {
      return "text";
    } else if (namefield === "number") {
      return "text";
    } else if (namefield === "lat") {
      return "text";
    } else {
      return namefield;
    }
  };

  return (
    <>
      {/* label */}
      {labelname && (
        <label className={labelclassName}>{labelname}</label>
      )}
      {/* input */}
      {fronticon ? (
        <span className="input-group-text" id="basic-addon2">
          {fronticon}
        </span>
      ) : (
        ""
      )}
      <input
        id={id}
        {...register(name, {
          pattern: checkpattern(pattern),
          required: required,
          maxLength: maxLength,
          minLength: minLength,
        })}
        className={`${borderstyle} ${className}`}
        style={style}
        name={name}
        type={checktype(type)}
        defaultValue={defaultValue}
        placeholder={placeholder}
        maxLength={maxLength}
        onKeyPress={(event) => {
          if (type === "number" && !/[0-9]/.test(event.key)) {
            event.preventDefault();
          } else if (type === "numberdot" && !/[0-9.]/.test(event.key)) {
            event.preventDefault();
          } else if (type === "lat" && !/^[0-9]*\.?[0-9]*$/.test(event.key)) {
            event.preventDefault();
          } else if (event.target.value === "" && event.code === "Space") {
            event.preventDefault();
          } else if (pattern === "special" && /[^\w]|_/g.test(event.key)) {
            event.preventDefault();
          }
        }}
        disabled={disabled ? "disabled" : ""}
        autoComplete={autoComplete}
        accept={accept}
        onPaste={(event) => {
          if (type === "number" && !/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />
      {icon ? (
        <span
          className="toggle-password"
          style={{ cursor: "pointer" }}
          // id="basic-addon2"
          onClick={iconclick}
        >
          {icon}
        </span>
      ) : (
        ""
      )}
      {/* span  */}
      <div className={`fv-plugins-message-container invalid-feedback ${spanclassName}`}>
        <span role="alert" className={errorclassName}>
          {errortype
            ? Error(
              name,
              errortype,
              keyName
            ) : ""}
        </span>
      </div>
    </>
  );
};
export default Input;
