import axios from "axios";
import store from "../Redux/Store/Store";
import { config } from "../config/config";

const baseUrl = process.env.REACT_APP_ENDPOINT;

const Api = async (type, url, token, data, resettoken) => {
  const obj = {
    url: `${baseUrl}/${url}`,
    method: type,
    headers: resettoken
      ? { Authorization: `Bearer ${resettoken}` }
      : token
      ? { Authorization: `${token}` }
      : "",
    data: data,
  };
  try {
    const apiResponse = await axios(obj);
    return apiResponse;
  } catch (err) {
    if (err.response.status === 403) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href =
        "https://beta8.moontechnolabs.com/kiosk-manufacture/front/v1/#/";
    } else {
      return err.response;
    }
  }
};

export default Api;

const ImageUploadAPI = async (image) => {
  const token = store.getState().AuthReducer.Auth.auth_token;
  const formdata = new FormData();
  formdata.append("file", image);
  const apiResponse = await axios({
    method: "POST",
    url: `${baseUrl}/file/upload`,
    headers: {
      Authorization: `${token}`,
    },
    data: formdata,
  });
  return apiResponse;
};

const KioskConfigFileUpload = async (obj, value) => {
  //token
  const token = store.getState().AuthReducer.Auth.auth_token;
  //formdata
  const formdata = new FormData();
  formdata.append("file", value);
  //call API
  const apiResponse = await axios({
    method: "POST",
    url: `${baseUrl}/file/upload?kiosk_config=1`,
    headers: {
      Authorization: `${token}`,
    },
    data: formdata,
  });
  return apiResponse;
};

export { ImageUploadAPI, KioskConfigFileUpload };
