/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useLayout } from "../../core";
import { AsideMenu } from "./AsideMenu";
import { Sidebar_Arrow } from "../../../../Assets";
import { Logo } from "../../../../Assets";

const AsideDefault = () => {
  //other
  const { config, classes } = useLayout();
  const { aside } = config;
  return (
    <div
      id="kt_aside"
      className={clsx("aside", classes.aside.join(" "))}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      {/* begin::Brand */}
      <div className="aside-logo flex-column-auto" id="kt_aside_logo">
        {/* begin::Logo */}
        {/* {aside.theme === "dark" && (
          <Link to="/dashboard">
            <img alt="Logo" className="h-25px logo" src={Logo} />
          </Link>
        )} */}
        {aside.theme === "light" && (
          <Link to="/dashboard">
            <img alt="Logo" className="h-25px logo" src={Logo} />
          </Link>
        )}
        {/* end::Logo */}
        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id="kt_aside_toggle"
            className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
          >
            <span className="svg-icon svg-icon-1 rotate-180">
              <img src={Sidebar_Arrow} className="mh-50px" alt="side_image"/>
            </span>
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className="aside-menu flex-column-fluid">
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}
    </div>
  );
};
export { AsideDefault };
