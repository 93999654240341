export const config = {
    access: {
        ROLES: 'Roles',
        USERS: 'Users',
        HOTELCHAIN: "Hotelchain",
        HOTEL: "Hotel",
        RESOURCES: "Resources",
        HOTELSTAFF: "Hotelstaff",
        Orders: "Orders",
        KIOSK: "Kiosk",
        KIOSKBREAKDOWN: "KioskBreakdown",
        RMA: "RMA",
        RESELLER: "Reseller",
        SERVICEPLAN: "ServicePlan",
        KIOSKSUBSCRIPTION: "KioskSubscription",
        AUTO: "Auto Payment",
        MANUALLY: "Manual Payment",
        KIOSKCONFIGURATION: "KioskConfiguration"
    },
    exportUrl: "https://beta8.moontechnolabs.com/kiosk-manufacture/api/",
    empty: {
        NA: "N/A"
    },
    MAX_FILE_SIZE_ALLOW: 25000000,
    ROLE_TYPES: {
        ROOMMATIK: 'Roommatik',
        HOTEL: 'Hotel',
        RESELLER: 'Reseller',
        HOTEL_CHAIN: 'Hotelchain',
        NON_HOTEL_CUSTOMER: 'Non hotel customer'
    },
    PLAN_TYPE: {
        MONTLY: "MONTHLY",
        YEARLY: "YEARLY"
    }
}