import React from "react";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { useSelector } from "react-redux";
import { fetchPermissionId, Permission } from "../../../../Helper/Permission";
import { config } from "../../../../config/config";
import { ExportData } from "../../../../config/language";

export function MenuInner() {
  //state
  const data = useSelector((state) => state.AuthReducer.Auth.permissions);
  return (
    <>
      <MenuItem title={ExportData("DASHBOARD")} to="/dashboard" />
      {(Permission(data, fetchPermissionId(data, config.access.ROLES), 1) ||
        Permission(data, fetchPermissionId(data, config.access.USERS), 1)) && (
        <MenuInnerWithSub
          title={ExportData("USER_MANAGEMENT")}
          menuPlacement="bottom-start"
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          {Permission(
            data,
            fetchPermissionId(data, config.access.ROLES),
            1
          ) && (
            <MenuItem
              title={ExportData("ROLES")}
              to="/roles"
              hasBullet={true}
              menuPlacement="right-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
            />
          )}
          {Permission(
            data,
            fetchPermissionId(data, config.access.USERS),
            1
          ) && (
            <MenuItem
              title={ExportData("USERS")}
              to="/users"
              menuPlacement="right-start"
              hasBullet={true}
              menuTrigger={`{default:'click', lg: 'hover'}`}
            />
          )}
        </MenuInnerWithSub>
      )}
      {(Permission(
        data,
        fetchPermissionId(data, config.access.HOTELCHAIN),
        1
      ) ||
        Permission(data, fetchPermissionId(data, config.access.HOTEL), 1) ||
        Permission(
          data,
          fetchPermissionId(data, config.access.HOTELSTAFF),
          1
        )) && (
        <MenuInnerWithSub
          title={ExportData("HOTEL_MANAGEMENT")}
          menuPlacement="bottom-start"
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          {Permission(
            data,
            fetchPermissionId(data, config.access.HOTELCHAIN),
            1
          ) && (
            <MenuItem
              title={ExportData("HOTEL_CHAIN")}
              to="/chains"
              hasBullet={true}
              menuPlacement="right-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
            />
          )}
          {Permission(
            data,
            fetchPermissionId(data, config.access.HOTEL),
            1
          ) && (
            <MenuItem
              title={ExportData("HOTELS")}
              to="/hotels"
              hasBullet={true}
              menuPlacement="right-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
            />
          )}
          {Permission(
            data,
            fetchPermissionId(data, config.access.HOTELSTAFF),
            1
          ) && (
            <MenuItem
              title={ExportData("HOTEL_STAFF")}
              to="/staff"
              hasBullet={true}
              menuPlacement="right-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
            />
          )}
        </MenuInnerWithSub>
      )}
      {(Permission(data, fetchPermissionId(data, config.access.Orders), 1) ||
        Permission(data, fetchPermissionId(data, config.access.RMA), 1)) && (
        <MenuInnerWithSub
          title={ExportData("ORDERS_MANAGEMENT")}
          menuPlacement="bottom-start"
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          {Permission(
            data,
            fetchPermissionId(data, config.access.Orders),
            1
          ) && (
            <MenuItem
              title={ExportData("ORDERS")}
              to="/orders"
              hasBullet={true}
              menuPlacement="right-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
            />
          )}
          {Permission(data, fetchPermissionId(data, config.access.RMA), 1) && (
            <MenuItem
              title={ExportData("RMA")}
              to="/rma"
              hasBullet={true}
              menuPlacement="right-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
            />
          )}
        </MenuInnerWithSub>
      )}
      {(Permission(data, fetchPermissionId(data, config.access.KIOSK), 1) ||
        Permission(
          data,
          fetchPermissionId(data, config.access.KIOSKBREAKDOWN),
          1
        )) && (
        <MenuInnerWithSub
          title={ExportData("KIOSK_MANAGEMENT")}
          menuPlacement="bottom-start"
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          {Permission(
            data,
            fetchPermissionId(data, config.access.KIOSK),
            1
          ) && (
            <MenuItem
              title={ExportData("KIOSK")}
              to="/kiosks"
              hasBullet={true}
              menuPlacement="right-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
            />
          )}
          {Permission(
            data,
            fetchPermissionId(data, config.access.KIOSKBREAKDOWN),
            1
          ) && (
            <MenuItem
              title={ExportData("KIOSK_BREAKDOWN")}
              to="/kioskbreakdown"
              hasBullet={true}
              menuPlacement="right-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
            />
          )}
        </MenuInnerWithSub>
      )}

      {Permission(data, fetchPermissionId(data, config.access.RESELLER), 1) && (
        <MenuItem
          title={ExportData("RESELLER")}
          to="/reseller"
          hasBullet={true}
          menuPlacement="right-start"
          menuTrigger={`{default:'click', lg: 'hover'}`}
        />
      )}
      {Permission(
        data,
        fetchPermissionId(data, config.access.SERVICEPLAN),
        1
      ) && (
        <MenuItem
          title={ExportData("SERVICEPLAN")}
          to="/serviceplan"
          hasBullet={true}
          menuPlacement="right-start"
          menuTrigger={`{default:'click', lg: 'hover'}`}
        />
      )}
      {Permission(
        data,
        fetchPermissionId(data, config.access.KIOSKSUBSCRIPTION),
        1
      ) && (
        <MenuItem
          title={ExportData("KIOSKSUBSCRIPTION")}
          to="/subscription"
          hasBullet={true}
          menuPlacement="right-start"
          menuTrigger={`{default:'click', lg: 'hover'}`}
        />
      )}
      {(Permission(data, fetchPermissionId(data, config.access.AUTO), 1) ||
        Permission(
          data,
          fetchPermissionId(data, config.access.MANUALLY),
          1
        )) && (
        <MenuInnerWithSub
          title={ExportData("PAYMENT")}
          menuPlacement="bottom-start"
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          {Permission(data, fetchPermissionId(data, config.access.AUTO), 1) && (
            <MenuItem
              title={ExportData("AUTO")}
              to="/payment/auto"
              hasBullet={true}
              menuPlacement="right-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
            />
          )}
          {Permission(
            data,
            fetchPermissionId(data, config.access.MANUALLY),
            1
          ) && (
            <MenuItem
              title={ExportData("MANUALLY")}
              to="/payment/manually"
              hasBullet={true}
              menuPlacement="right-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
            />
          )}
        </MenuInnerWithSub>
      )}
      {Permission(
        data,
        fetchPermissionId(data, config.access.KIOSKCONFIGURATION),
        1
      ) && (
        <MenuItem
          title={ExportData("KIOSK_CONFIGURATION")}
          to="/kioskconfiguration"
          hasBullet={true}
          menuPlacement="right-start"
          menuTrigger={`{default:'click', lg: 'hover'}`}
        />
      )}
    </>
  );
}
