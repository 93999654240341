import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../Shared_Components/Input/Input";
import {
  AuthUser,
  SetLanguageData,
} from "../../../Redux/Action/AuthAction/AuthAction";
import API from "../../../Api/Api";
import { SetData } from "../../../Api/LocalStorage";
import toast from "react-hot-toast";
import { ExportData } from "../../../config/language";
import { backEndMessages } from "../../../Helper/common";

const Login = () => {
  //state
  const [eye, seteye] = useState(false);
  const currentLang = useSelector((state) => state.AuthReducer.Language);

  //other
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  //call language API
  useEffect(() => {
    SetDefaultLanguage();
  }, []);

  //get language API
  const SetDefaultLanguage = async () => {
    const response = await API(
      "GET",
      `master/get/translated-words?column=${currentLang}`
    );
    if (response.data.status) {
      // dispatch(SetLanguage(currentLang));
      dispatch(SetLanguageData(response.data.data));
    } else {
      console.log("error", response);
    }
  };

  //handlelogin
  const handleLogin = async (data) => {
    const senddata = {
      username: data.email.trim(),
      password: data.password,
    };
    const response = await API("POST", "users/signIn", "", senddata);
    if (response.data.status) {
      dispatch(AuthUser(response.data.data));
      SetData("data", response.data.data);
      navigate("/dashboard");
    } else {
      toast.error(backEndMessages(response.data.message));
    }
  };
  return (
    <div>
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">{ExportData("LOGIN_HEADER_TXT")}</h1>
        {/* <div className="text-gray-400 fw-bold fs-4">
          New Here?{" "}
          <Link to="/auth/registration" className="link-primary fw-bolder">
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}
      <form onSubmit={handleSubmit(handleLogin)}>
        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <Input
            register={register}
            name="email"
            required={true}
            errortype={errors.email?.type}
            className="form-control form-control-lg form-control-solid"
            pattern="email"
            labelname={ExportData("EMAIL")}
            labelclassName="form-label fs-6 fw-bolder text-dark"
            autoComplete="off"
            maxLength={50}
            keyName="EMAIL"
          />
        </div>
        {/* end::Form group */}
        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              {ExportData("PASSWORD")}
            </label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to="/forgot-password"
              className="link-primary fs-6 fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              {ExportData("FORGOT_PASSWORD")} ?
            </Link>
            {/* end::Link */}
          </div>
          <div className="position-relative mb-3">
            <Input
              register={register}
              name="password"
              required={true}
              errortype={errors.password?.type}
              className="form-control form-control-lg form-control-solid remove_invalid"
              type={eye ? "text" : "password"}
              autoComplete="off"
              data={watch("password")}
              maxLength={25}
              keyName="PASSWORD"
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-25"
              data-kt-password-meter-control="visibility"
              onClick={() => seteye(!eye)}
            >
              {eye ? (
                <i className="bi bi-eye fs-2"></i>
              ) : (
                <i className="bi bi-eye-slash fs-2"></i>
              )}
            </span>
          </div>
        </div>
        <div className="text-center">
          <button className="btn btn-lg btn-primary w-100 mb-5" type="submit">
            <span className="indicator-label">{ExportData("LOGIN_BTN")}</span>
          </button>
        </div>
      </form>
    </div>
  );
};
export default Login;
