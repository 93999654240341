import CryptoJS from "crypto-js";

// Encrypt
export const SetData = (name, value) => {
  if (name === "data") {
    const encrptName =
      "rjtyhirmfdvmsanfiojfw0qfujscmksaifjew0r9gupqwerudjq0hpyqadi2056t7ybhvmsadcb";
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      "23948yrdhbwssndchlw809gvnsjcbQ3RB28YR2RG2HKWHQFDBKFUGCYW9RTG845YTW4OGHESMGBHGVUY97834Y2RADSFAD"
    ).toString();
    localStorage.setItem(encrptName, ciphertext);
    return ciphertext;
  }
};

// Decrypt
export const GetData = (data) => {
  if (data === "data") {
    let getData = localStorage.getItem(
      "rjtyhirmfdvmsanfiojfw0qfujscmksaifjew0r9gupqwerudjq0hpyqadi2056t7ybhvmsadcb"
    );
    if (getData) {
      var bytes = CryptoJS.AES.decrypt(
        getData,
        "23948yrdhbwssndchlw809gvnsjcbQ3RB28YR2RG2HKWHQFDBKFUGCYW9RTG845YTW4OGHESMGBHGVUY97834Y2RADSFAD"
      );
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }
  }
};
