import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    Modules: {},
};
const ModuleReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetModules:
            return {
                ...state,
                Modules: payload,
            };
        default:
            return state;
    }
};
export default ModuleReducer;
